import './index.scss';
import { useState } from 'react';
import FaIcon from '@src/Components/FaIcon';
import { useHistory } from 'react-router-dom';
import { Button, Loader, Navbar } from 'rsuite';
import { Notify } from '@src/Tools/Utils/React';
import useFetch from '@src/Tools/Hooks/useFetch';
import useStore from '@src/Tools/Store/useStore';
import Logo from '@assets/icons/Logo-element.svg';
import useDashboard from '@src/Tools/Hooks/useDashboard';
import { promiseQueueRunner } from '@src/Tools/Utils/Async';
import { setDevProjects, setProdProjects } from '../../../Tools/Store/actions/DashboardActions';

const NavBar = (props: { onSubscribe: () => void }) => {
	const { onSubscribe } = props;
	const { Get } = useFetch();
	const { dispatch } = useStore();
	const { location, push, replace } = useHistory();
	const [loading, setLoading] = useState(false);
	const { devProjects, prodProjects } = useDashboard();

	// ? ------------------------------ Vars -------------------------------
	const path_items = location.pathname.split('/');
	const pname = path_items[3];
	const isDev = path_items[2] === 'dev';
	const projects = isDev ? devProjects : prodProjects;
	const setProjects = isDev ? setDevProjects : setProdProjects;
	// ? ---------------------------- Functions ----------------------------

	const getStatuses = async () => {
		const getDevStatuses = async () => {
			try {
				const res = await Get({ url: `/projects/monitoring/dev-service-status-all` });
				console.log(res);
				dispatch(setDevProjects(res));
			} catch (err: any) {
				Notify.error('AHQ service is down!');
			}
			// dispatch(setLoading(false));
		};

		const getProdStatuses = async () => {
			try {
				const res = await Get({ url: `/projects/monitoring/service-status-all` });
				console.log(res);
				dispatch(setProdProjects(res));
			} catch (err: any) {
				Notify.error('AHQ service is down!');
			}
			// dispatch(setLoading(false));
		};

		await promiseQueueRunner([getProdStatuses, getDevStatuses]);
		setLoading(false);
	};

	const reload = async () => {
		// dispatch(setLoading(true));
		setLoading(true);
		if (location.pathname.includes('/service-status')) {
			// ? reload the modules status of current service in service-status page
			try {
				const res = await Get({ url: `/projects/monitoring/${isDev ? 'dev-' : ''}service-status/${pname}` });
				const newProjects = { ...projects, [pname]: res };
				// dispatch(setLoading(false));
				setLoading(false);
				dispatch(setProjects(newProjects));
			} catch {}
		} else {
			getStatuses();
		}
	};

	// ---------------------------------------------------------------------
	return (
		<div className='navbar-layout'>
			<Navbar className='navbar'>
				<Navbar.Brand className='nav-brand'>
					<img className='logo' alt='logo' src={Logo} onClick={() => replace('/')} />
				</Navbar.Brand>
				<div className='subscribe-container'>
					{!loading && <FaIcon fa='r-arrows-rotate' onClick={reload} />}
					{loading && <Loader />}
					<Button appearance='ghost' onClick={onSubscribe}>
						Subscribe
					</Button>
				</div>
			</Navbar>
		</div>
	);
};

export default NavBar;
