import './index.scss';
import Aos from 'aos';
import NavBar from './Navbar';
import Footer from './Footer';
import useInputs from 'use-inputs';
import { Button, Modal } from 'rsuite';
import { FC, useEffect, useState } from 'react';
import useStore from '@src/Tools/Store/useStore';
import useFetch from '@src/Tools/Hooks/useFetch';
import { Notify, classes } from '@src/Tools/Utils/React';
import useDashboard from '@src/Tools/Hooks/useDashboard';
import { promiseQueueRunner } from '@src/Tools/Utils/Async';
import AlreadySubscribed from '@assets/Images/already-subscribed.png';
import EditableInput from '@src/Components/EditableInput/EditableInput';
import { ReactComponent as EmailSent } from '@assets/Images/verification-email-sent.svg';
import { setDevProjects, setLoading, setProdProjects } from '@src/Tools/Store/actions/DashboardActions';
import { Else, If } from 'tsx-statements';
import { useData } from '@src/Tools/Hooks/useData';

const Layout: FC = ({ children }) => {
	const { dispatch } = useStore();
	const { Get } = useFetch(undefined, { throwError: true });
	const [openModal, setOpenModal] = useState(false);
	const emailState = useData({ message: '', sent: false });
	const [buttonLoading, setButtonLoading] = useState(false);
	const { devProjects, prodProjects } = useDashboard();
	const { register, validOf, valueOf, resetInputs } = useInputs({
		validation: { email: { regex: 'email', errorMsg: 'Invalid Email', required: true } },
	});

	// ? --------------------------- Functions ----------------------------
	const onCloseModal = () => {
		resetInputs();
		setOpenModal(false);
		emailState.discard();
	};

	const onSubscribe = async () => {
		if (!validOf('email').isValid) return;
		const email = valueOf('email');
		setButtonLoading(true);
		try {
			const { message } = await Get({ url: `/projects/monitoring/subscribe/${email}` });
			// setMessage(message);
			emailState.set.temp({ message, sent: true });
		} catch (error: any) {
			emailState.set.temp({ message: error.message, sent: false });
		}
		setButtonLoading(false);
	};

	const getStatuses = async () => {
		dispatch(setLoading(true));
		let dev = {};
		let prod = {};
		const getDevStatuses = async () => {
			try {
				dev = await Get({ url: `/projects/monitoring/dev-service-status-all` });
				dispatch(setDevProjects(dev));
			} catch (err: any) {
				dispatch(setLoading(false));
				Notify.error('AHQ service is down!');
			}
		};

		const getProdStatuses = async () => {
			try {
				prod = await Get({ url: `/projects/monitoring/service-status-all` });
				dispatch(setProdProjects(prod));
			} catch (err: any) {
				dispatch(setLoading(false));
				Notify.error('AHQ service is down!');
			}
		};

		await promiseQueueRunner([getProdStatuses, getDevStatuses]);
		dispatch(setLoading(false));
	};

	// ? -------------------------------- useEffect -----------------------------
	useEffect(() => {
		if (!devProjects && !prodProjects) getStatuses();
	}, [devProjects, prodProjects]);

	// Initialize AOS
	useEffect(() => Aos.init(), []);

	// --------------------------------------------------------------------
	return (
		<div className='root-layout'>
			<NavBar onSubscribe={() => setOpenModal(open => !open)} />
			<div className='bg-circle' />
			<div className='main-content'>
				{children}
				<Footer />
			</div>
			<Modal open={openModal} onClose={onCloseModal} size='sm' className='subscribe-modal'>
				<Modal.Header>Subscribe to Status</Modal.Header>
				<Modal.Body>
					<If condition={!!emailState.temp.message}>
						<div className='subscribe-result'>
							{!!emailState.temp.sent ? (
								<EmailSent className='email-sent' />
							) : (
								<img className='already-subscribed' src={AlreadySubscribed} alt='' />
							)}
							<p>{emailState.temp.message}</p>
						</div>
						<Else>
							<div>
								<p>Enter your email to get updates from AutoHQ Service Status.</p>
								<EditableInput
									required
									label='Email Address'
									placeholder='example@gmail.com'
									{...register('email')}
									isValid={validOf('email').isValidDirty}
									errorMessage={validOf('email').msg}
								/>
								<Button
									disabled={!validOf('email').isValid}
									{...classes({ disabled: !validOf('email').isValid })}
									loading={buttonLoading}
									onClick={onSubscribe}>
									Subscribe
								</Button>
							</div>
						</Else>
					</If>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default Layout;
