import { createReducer } from '@reduxjs/toolkit';
import { Swiper as SwiperType } from 'swiper/types';
import {
	clearProjectsStatus,
	setDevProjects,
	setIsLight,
	setLoading,
	setProdProjects,
	setSwiper,
	setUploadProgress,
} from '../actions/DashboardActions';

type InitStateType = {
	devProjects: any;
	isLight: boolean;
	prodProjects: any;
	isLoading: boolean;
	uploadProgress: number;
	whitePageYOffset: number;
	swiper: SwiperType | undefined;
};

const initState: InitStateType = {
	devProjects: undefined,
	prodProjects: undefined,
	isLoading: false,
	isLight: false,
	swiper: undefined,
	uploadProgress: 0,
	whitePageYOffset: 0,
};

const DashboardReducer = createReducer(initState, {
	//* upload progress
	[setUploadProgress.type]: (state, { payload }) => ({ ...state, uploadProgress: payload }),

	//* loading
	[setLoading.type]: (state, { payload }) => ({ ...state, isLoading: payload }),

	//* light background
	[setIsLight.type]: (state, { payload }) => ({ ...state, isLight: payload }),

	//* set swiper
	[setSwiper.type]: (state, { payload }) => ({ ...state, swiper: payload }),

	//* clear projects status
	[clearProjectsStatus.type]: state => ({ ...state, devProjects: undefined, prodProjects: undefined }),

	//* set dev projects status
	[setDevProjects.type]: (state, { payload }) => ({ ...state, devProjects: payload }),

	//* set prod projects status
	[setProdProjects.type]: (state, { payload }) => ({ ...state, prodProjects: payload }),
});

export default DashboardReducer;
