import './index.scss';
import { ReactComponent as Logo } from '@assets/icons/Logo-element.svg';

const Footer = () => {
	return (
		<div className='footer-layout'>
			<p>Powered by</p>
			<Logo />
		</div>
	);
};

export default Footer;
