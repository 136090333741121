import { lazy } from 'react';
import { GuestPath } from './Guard';
import { Path } from './RouteRenderer';
import { Redirect } from 'react-router-dom';
import { CONFIG } from '../Config/constants';

export const routesArray: Path[] = [
	{
		exact: true,
		guard: GuestPath,
		path: ['/', '/home'],
		component: lazy(() => import('../../Views/Pages/Home')),
	},
	{
		exact: false,
		guard: GuestPath,
		path: ['/service-status'],
		component: lazy(() => import('../../Views/Pages/Status')),
	},
	{
		exact: true,
		path: ['/verify-email/:email/:token'],
		component: lazy(() => import('../../Views/Pages/SubscribeStatus')),
	},
	{
		exact: true,
		path: ['/unsubscribe/:email'],
		component: lazy(() => import('../../Views/Pages/SubscribeStatus')),
	},
	{
		path: ['*'],
		exact: true,
		component: () => <Redirect to={CONFIG.BASE_URL} />,
	},
];

export default routesArray;
